<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'StackedColumnChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    xAxisTitle: {
      type: String,
      default: '',
    },
    yAxisTitle: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

    chart.data = this.data

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.categoryField
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.labels.template.rotation = 270
    categoryAxis.renderer.labels.template.hideOversized = false
    categoryAxis.renderer.minGridDistance = 20
    categoryAxis.title.text = this.xAxisTitle

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = this.yAxisTitle
    valueAxis.renderer.inside = true
    valueAxis.renderer.labels.template.disabled = true
    valueAxis.min = 0

    const series = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.series.length; i++) {
      const s = chart.series.push(new am4charts.ColumnSeries())
      s.dataFields.valueY = this.series[i].valueField
      s.dataFields.categoryX = this.categoryField
      s.name = this.series[i].seriesName
      s.tooltipText = '{name}: [bold]{valueY}[/]'
      s.stacked = true
      s.columns.template.width = am4core.percent(95)
      // s.columns.template.fill = am4core.color(this.series[i].color)
      s.sequencedInterpolation = true
      // Make it stacked
      s.stacked = true
      // Configure columns
      s.columns.template.width = am4core.percent(60)
      s.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

      // Add label
      const labelBullet = s.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.text = '{valueY}'
      labelBullet.locationY = 0.5
      labelBullet.label.hideOversized = true

      series.push(s)
    }

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.behavior = 'panXY'
    chart.cursor.xAxis = categoryAxis
    chart.cursor.snapToSeries = series

    chart.exporting.menu = new am4core.ExportMenu()

    this.chart = chart
    // Legend
    chart.legend = new am4charts.Legend()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
