<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="distributeurData.avatar"
              :text="avatarText(`${distributeurData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(distributeurData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ distributeurData.prenom }} {{ distributeurData.nom }}
                </h4>
                <span class="card-text"> Contact: {{ distributeurData.telephone }}</span><br>
                <span class="card-text"> Region: {{ distributeurData.region }}</span><br>
                <span class="card-text"> Zone: {{ distributeurData.zone }}</span><br>
                <span class="card-text"> Visites: {{ distributeurData.nbr_visites }}</span><br>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <!--          <div class="d-flex align-items-center mt-2">-->
          <!--            <div class="d-flex align-items-center mr-2">-->
          <!--              <b-avatar-->
          <!--                variant="light-primary"-->
          <!--                rounded-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="AwardIcon"-->
          <!--                  size="18"-->
          <!--                />-->
          <!--              </b-avatar>-->
          <!--              <div class="ml-1">-->
          <!--                <h5 class="mb-0">-->
          <!--                  {{ distributeurData.score }}-->
          <!--                  1500-->
          <!--                </h5>-->
          <!--                <small>Score</small>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="d-flex align-items-center">-->
          <!--              <b-avatar-->
          <!--                variant="light-success"-->
          <!--                rounded-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="TrendingUpIcon"-->
          <!--                  size="18"-->
          <!--                />-->
          <!--              </b-avatar>-->
          <!--              <div class="ml-1">-->
          <!--                <h5 class="mb-0">-->
          <!--                  80%-->
          <!--                </h5>-->
          <!--                <small>Taux</small>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="6"
          md="4"
        >
          <table
            v-if="distributeurData.commercial_r"
            class="mt-2 mt-xl-0 w-100"
          >
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageCommercial"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Commercial :</span>
              </th>
              <td class="pb-50">
                {{ distributeurData.commercial_r.first_name }} {{ distributeurData.commercial_r.last_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePeremption"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Produits proche de la péremption: </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ distributeurData.nombre_distributeurs }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageSeuilMini"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Produits en dessous du seuil minimal : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ distributeurData.nombre_tournees }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageRupture"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1"> Produits en ruptures : </span>
              </th>
              <td class="pb-50">
                {{ distributeurData.nombre_pdv }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col
          cols="6"
          md="4"
        />
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Stock</span>
          </template>
          <!-- button -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn_export ml-2"
            @click="refresh()"
          >
            <feather-icon icon="RefreshCcwIcon" />
            Synchronisation
          </b-button>
          <ListeStock :rows="listeStock" />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePeremption"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Visites</span>
          </template>
          <b-card
            title="Représentation graphique"
            class="mt-5"
          >
            <VisiteListeMainVue />
          </b-card>

        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePeremption"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Visites par semaine</span>
          </template>
          <b-row
            v-for="item in visitesParSemaine"
            :key="item.id"
          >
            <b-col md="8">
              <ListeProduitsVisitesParSemaine
                :produits-visites="item"
                :title="item[0].semaine_label"
              />
            </b-col>
            <!--            <b-col md="4">-->
            <!--              <b-card-->
            <!--                title="Représentation graphique"-->
            <!--                class="mt-5"-->
            <!--              >-->
            <!--                <stacked-column-chart-->
            <!--                  :data="visitesParSemaine"-->
            <!--                  category-field="category"-->
            <!--                  :series="visitesParSemaine"-->
            <!--                  x-axis-title="Les produits afridia"-->
            <!--                  y-axis-title="Quantité des produits"-->
            <!--                />-->
            <!--              </b-card>-->
            <!--            </b-col>-->

          </b-row>

        </b-tab>

      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BButton, BButtonGroup, BCard, BCol, BRow, BTab, BTabs, VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'

import { $themeColors } from '@themeConfig'
import ListeStock from '@/views/afridia/distributeurs/details/ListeStock.vue'
import { API_URL } from '@/helpers/global-scops'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import ListeVisite from '@/views/afridia/distributeurs/details/ListeVistie.vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'
import { getWeek } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import ListeProduitsVisitesParSemaine from '@/views/afridia/distributeurs/details/ListeProduitsVisitesParSemaine.vue'
import VisiteListeMainVue from '@/views/afridia/distributeurs/details/VisiteListeMainVue.vue'

const $earningsStrokeColor2 = 'rgb(1,132,38)'
const $earningsStrokeColor3 = 'rgba(40,199,111,0.24)'

export default {
  name: 'MainVue',
  components: {
    VisiteListeMainVue,
    ListeProduitsVisitesParSemaine,
    BButtonGroup,
    StackedColumnChart,
    ListeVisite,
    ListeStock,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      visites: [],
      visitesParSemaine: [],
      listeStock: [],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Visites',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      distributeurData: {},
      // eslint-disable-next-line global-require
      imagePeremption: require('@/assets/images/icons/passer.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/stock.png'),
      // eslint-disable-next-line global-require
      imageCommercial: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageSeuilMini: require('@/assets/images/icons/down-arrow.png'),
      // eslint-disable-next-line global-require
      imageRupture: require('@/assets/images/icons/rupture-de-stock.png'),
      userData: {},
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  created() {
    this.getDistributeur()
    this.getWeeksVisites()
    this.getSemaineVisiteDistributeur()
  },
  methods: {
    getStock() {
      this.$http.get(`${API_URL}distributeurs-produit-stocks/?distributeur=${this.distributeurData.id}`)
        .then(response => {
          this.listeStock = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDistributeur() {
      this.$http.get(`${API_URL}distributeurs/${this.$route.params.id}/`)
        .then(response => {
          this.distributeurData = response.data
          this.getStock()
        })
        .catch(error => {
          console.log(error)
        })
    },
    refresh() {
      this.$http.get(`${API_URL}distributeurs-produit-synchronization/${this.distributeurData.id}/`)
        .then(() => {
          this.getStock()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getWeekVisites(week) {
      this.$http.get(`${API_URL}visite-distributeur-par-semaine?distributeur_id=${this.$route.params.id}&semaine=${week}`)
        .then(response => {
          // fais une boucle foreach sur response.data
          const chartData = []
          const chartSeries = [
            {
              valueField: 'Produit',
              seriesName: 'Produit',
            },
            {
              valueField: 'Stock1',
              seriesName: 'Stock 1e Visite',
            },
            {
              valueField: 'Stock2',
              seriesName: 'Stock 2e Visite',
            },
            {
              valueField: 'DLUO',
              seriesName: 'Stock 2e Visite',
            },
            {
              valueField: 'Entrees',
              seriesName: 'Stock 2e Visite',
            },
            {
              valueField: 'Sortie',
              seriesName: 'Stock 2e Visite',
            },
          ]
          response.data.forEach(element => {
            chartData.push({
              Produit: element.produit_r.produit_r.nom_produit,
              Stock1: element.stock1,
              Stock2: element.stock2,
              Entrees: element.stockCommande,
              Sorties: element.stockSortie,
            })

            // this.visites.push({
            //   produits: element.produits,
            //   title: element.interval,
            //   semaine: element.semaine,
            //   chartData,
            //   chartSeries,
            // })
            // chartData = []
          })
          this.visites.sort((a, b) => b.semaine - a.semaine)
          console.log(this.visites)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getWeeksVisites() {
      this.getLastWeeks().forEach(element => {
        this.getWeekVisites(element)
      })
      console.log(this.getLastWeeks())
    },

    getLastWeeks(countWeek = 5) {
      // Obtenir la date actuelle
      // const today = new Date()

      // Tableau pour stocker les numéros de semaine
      const lastFiveWeeks = []
      const actualWeek = getWeek(new Date())

      // Boucle pour obtenir les 5 dernières semaines
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < countWeek; i++) {
        if (actualWeek - i > 0) {
          // Ajouter le numéro de semaine au tableau
          lastFiveWeeks.push(actualWeek - i)
        }
      }
      return lastFiveWeeks
    },

    getWeekNumber(date) {
      return getWeek(new Date(date))
    },
    getSemaineVisiteDistributeur() {
      this.$http.get(`${API_URL}semaine-visite-distributeur/?distributeur_id=${this.$route.params.id}`)
        .then(response => {
          this.visitesParSemaine = response.data.reduce((acc, obj) => {
            console.log('acc', acc)
            console.log('obj', obj)
            const semaine = obj.semaine_number
            if (!acc[semaine]) {
              acc[semaine] = []
            }
            acc[semaine].push(obj)
            return acc
          }, {})
          // this.visitesParSemaine.sort((a, b) => a - b)
          console.log(this.visitesParSemaine)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}

</script>

<style scoped>

</style>
